.starsContainer {
    display: flex;
    align-items: center;
}

.avgScoreContainer {
    display: flex;
    align-items: center;
    font-size: .65em;
    cursor: pointer;
}

.scoreNumber {
    margin-right: .5rem;
    font-weight: 700;
}

.scoreGiveText {
    font-size: .65em;
    margin-right: 8px;
}