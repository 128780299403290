@import '~@emplo/react-inspinia/dist/inspinia/scss/_variables';

.stageIndicator {
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    margin-right: 4px;

    &.gray {
        background-color: $light-gray;
    }

    &.red {
        background-color: $red;
    }

    &.green {
        background-color: $navy;
    }

    &.greenGlass {
        background-color: rgba(#1AB394, .53);
    }

    &.blue {
        background-color: $blue;
    }
}