@import '../../themes/ats/variables';

.photoWrapper {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    position: relative;
    float: left;
    margin-right: 8px;

    img {
        width: 100%;
        opacity: 0;
    }
}

.item {
    display: block;

    &,
    &:hover {
        color: $color-text-default;
    }
}