@import '../../../themes/ats/variables';

.container {
    height: auto !important;
    position: relative;

    &:global(.react-tagsinput--focused) {
        border-color: $navy;
    }

    input[type=text] {
        width: 100%;
        min-width: 200px;
        margin-top: 6px;
        margin-bottom: 6px;

        &,
        &:focus,
        &:active {
            border: none;
            outline: none;
        }
    }

    >span {
        display: flex;
        flex-wrap: wrap;

        :global(.react-autosuggest__container) {
            flex: 1;
        }
    }
}

.tag {
    background: $navy;
    border-radius: 2px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 15px;
    padding: 2px 8px;
    margin-top: 6px;
    margin-bottom: 6px;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &Text {
        color: white;
    }

    &Remove {
        color: white;
        font-weight: 700;
        margin-left: 0.5em;
        cursor: pointer;
        padding: 2px;
    }
}

.dropdownContainer {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;

    /* popper placement */
    &:global(.top) {
        top: unset;
        bottom: 100%;
    }
}

:global(.react-autosuggest__container) {
    display: flex;
    flex-direction: column;

    :global(.react-autosuggest__suggestions-container) {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        background: white;
        border: solid 1px $border-color;
        border-radius: 2px;
        display: none;
        max-height: 200px;
        overflow: auto;

        &:global(.react-autosuggest__suggestions-container--open) {
            display: block;
        }

        ul {
            padding: 0;
            margin: 0;

            li {
                padding: 6px 20px;
                margin: 0;
                list-style: none;
                color: $label-badge-color;
            }
        }

        :global(.react-autosuggest__suggestion--highlighted) {
            background-color: $navy;
            color: white;
        }
    }
}