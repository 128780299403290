.container {

    display: flex;

    >* {
        padding-left: 2px;
        padding-right: 2px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}