@import '../../themes/ats/variables';

.dropdownIndicator {
    display: flex;
    width: 24px;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    pointer-events: none;
}

.clearIndicator {
    color: $color-danger;
    cursor: pointer;
}