.container {
    width: 525px;
    margin: 10vh auto auto;
}

.welcome {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 14px;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 72px;
}

.btnLogin {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0;
    font-size: 14px;
    font-weight: 700;

    -webkit-box-shadow: 0px 2px 4px 0px rgba(209, 218, 222, 1);
    -moz-box-shadow: 0px 2px 4px 0px rgba(209, 218, 222, 1);
    box-shadow: 0px 2px 4px 0px rgba(209, 218, 222, 1);

    &Inner {
        flex: 1;
        position: relative;
    }

    &Icon {
        height: 20px;
        width: auto;
        position: absolute;
        left: 16px;
        top: 0;
    }
}