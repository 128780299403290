@import '../../../../themes/ats/variables';

.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
}

.filtersContainer {
    padding: 16px 20px;
    background: $gray;
}

.listContainer {
    overflow: auto;
}

.searchLabel {
    margin-left: 4px;
}

.item {
    display: flex;
    align-items: center;
    padding: 24px 20px;
    transition: background-color 200ms;
    border-bottom: solid 1px $border-color;
    margin-bottom: 0;

    &:hover {
        cursor: pointer;
        background: darken(white, 2%);
    }

    &PersonName {
        font-size: 16px;
        font-weight: 600;
    }
}

.checkboxDisabled {
    &::before {
        background-color: rgba(46, 64, 80, 0.5) !important;
    }
}