.chart {
    margin: auto;
}

.legend {
    margin: 32px auto 0;

    &Item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }

        &Color {
            width: 40px;
            height: 14px;
            margin-right: 6px;
        }

        &Name {
            flex: 1;
        }
    }
}