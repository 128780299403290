@import '../../../themes/ats/variables';
@import '~@emplo/react-inspinia/dist/inspinia/scss/_variables';

:global(.project-list) .table {
    tr td {
        padding: 0;

        a {
            padding: 24px 10px;
            color: $color-text-default;

            &:hover {
                color: $color-text-default;
            }
        }

        &:last-child {
            padding: 24px 32px 24px 10px;
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: 4fr 3fr 3fr 3fr 3fr;
    grid-template-areas:
        "title title title title title"
        "position manager owner candidates deadline";

    &Title {
        grid-area: title;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
    }

    &Position {
        grid-area: position;
    }

    &Manager {
        grid-area: manager;
    }

    &Owner {
        grid-area: owner;
    }

    &Candidates {
        grid-area: candidates;

        .newApplicationsLabel {
            font-size: 9px;
            font-weight: 700;
            border-radius: 10px;
            padding: 3px 6px;
            margin-left: 8px;
        }
    }

    &Deadline {
        grid-area: deadline;
    }
}

.filterBtn {
    border-radius: 18px;
}

.btn {
    &Actions {
        width: 36px;
        height: 32px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }
}