@import '../../../../themes/ats/variables';

.listContainer {
    display: table;
    width: 100%;
}

.item {
    display: table-row;
}

.col {
    display: table-cell;
    vertical-align: middle;
    border-bottom: solid 1px $border-color;
    padding: 20px 0 20px 12px;

    &:first-child {
        padding-left: 24px;
    }

    &:last-child {
        padding-right: 24px;
    }

    &Name {
        width: 100%;

        &Row {
            font-size: 16px;
        }
    }
}

.toolsContainer {
    display: flex;

    >button {
        width: 36px;
        height: 32px;
        padding: 0;
        margin-right: 1em;

        &:last-child {
            margin-right: 0;
        }
    }
}