@import '../../../themes/ats/variables';

.name {
  font-size: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 800px;
}

.btnOtherApplications,
.address,
.phone,
.email {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

.linkButton {
  color: $color-text-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  i:global(.fa) {
    font-size: 18px;
  }

  &,
  &:hover {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  &:hover {
    color: $navy;
  }

  span {
    transition: all 150ms;
  }
}

.photoContainer {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.score {
  font-size: 20px;
  margin-top: 5px;
}