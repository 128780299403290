@import '../../../themes/ats/variables';

:global(.project-list) .table {
    tr td {
        padding: 0;
    }

    a {
        display: block;
        padding: 32px 10px;

        color: $color-text-default;

        &:hover {
            color: $color-text-default;
        }
    }
}

.col {
    &Title {
        width: 40%;
        font-size: 16px;
        line-height: 1;
        font-weight: 600;
    }

    &Used,
    &CreatedBy,
    &LastEdited {
        width: 20%;
    }
}

.btn {
    &Actions {
        width: 36px;
        height: 32px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
    }
}