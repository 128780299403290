@import '../../../../themes/ats/variables';

$padding-v: 20px;
$padding-col: $padding-v 12px;
$button-height: 32px;

.listContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    margin-bottom: 8px;
}

.col {
    padding: $padding-col;

    &:first-child {
        padding-left: 32px;
    }

    &:last-child {
        padding-right: 24px;
    }

    &Name {
        flex: 1;

        .stageName {
            font-size: 16px;
            font-weight: 600;
        }
    }

    &StageType {
        i {
            font-size: 16px;
            vertical-align: middle;
        }
    }

    &Actions {
        min-height: $button-height + 2 * $padding-v;
    }
}

.stageTypeColor {
    &Danger {
        color: $red;
    }

    &Info {
        color: $lazur;
    }

    &Warning {
        color: $yellow;
    }

    &Primary {
        color: $navy;
    }
}

.toolsButton {
    width: 36px;
    height: $button-height;
    padding: 0;

    >i {
        text-align: center;
        vertical-align: middle;
        font-size: 16px;
        line-height: 0;
    }

    &:not(:last-child) {
        margin-right: 1em;
    }
}

.row.editable {

    &:hover,
    &.dragging,
    .editing {
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
}

.dragHandle {
    align-self: stretch;
    opacity: 0;
}

.row:hover,
.row.editing,
.row.dragging {
    .dragHandle {
        opacity: 1;
    }
}