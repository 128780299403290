@import '../../../../themes/ats/variables';

.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 24px;
    border: none;
    border-bottom: solid 1px $border-color;
    background: white;
    color: $color-text-default;
    transition: background-color 500ms;

    &Active,
    &:hover {
        background-color: $gray;
    }

    &:hover {
        color: $color-text-default;
    }
}

.dateStageRow,
.titleRow {
    display: flex;
    justify-content: space-between;
}

.dateStageRow {
    h6 {
        font-size: 11px;
        font-weight: 600;
    }
}

.applicationDate {
    font-size: 11px;
    font-weight: 600;
}

.stage {
    font-size: 11px;
    font-weight: 600;
}

.score {
    font-size: 16px;
}