.ats-p-h-md {
    @include ats-padding-h($padding-h-md);
}

.ats-p-v-md {
    @include ats-padding-v($padding-v-md);
}

.ats-p-md {
    @include ats-padding-h($padding-h-md);
    @include ats-padding-v($padding-v-md);
}