@import '../../../themes/ats/variables';

:global(.project-list) .table {
  tr td {
    padding: 0;

    a {
      display: block;
      padding: 24px 10px;

      color: $color-text-default;

      &:hover {
        color: $color-text-default;
      }
    }
  }
}

.avatarContainer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.recruiteeName,
.recruiteePhoto,
.recruiteeEmail {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 15vw;
}

.recruiteePhoto {
  max-width: 10vw;
}

.recruiteeEmail {
  max-width: 15vw;
}

.col {
  &Title {
    width: 40%;
    font-size: 16px;
    font-weight: 600;
  }

  &Phone {
    width: 20%;
  }

  &Email {
    width: 20%;
  }

  &LastApplication {
    width: 20%;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 10px;
  padding: 0;
}

.cvModal {
  min-width: 800px;
  max-width: 90vw;
}