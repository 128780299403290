@import '../../../../themes/ats/variables';

.option {
    i {
        color: $navy;
        font-size: 1.3em;
    }

    span {
        font-size: 13px;
        line-height: 20px;
        margin-left: 8px;
    }
}

.typeSelect {
    width: 100%;

    :global(.type-select__control) {
        height: 38px;

        &--menu-is-open,
        &--is-focused {

            &,
            &:hover {
                border-color: $navy;
            }
        }
    }
}