@import '../../../themes/ats/variables';

$builderContainerLeftPadding: 8px; // builder container's padding
$fieldContainerLeftPadding: 16px; // element editor container's padding
$handleWidth: $padding-v-md; // width of the handle for drag&drop

.container {
    display: flex;
    align-items: stretch;
    border-radius: $border-radius 0 0 $border-radius;
    transition: all 200ms;

    &:not(.dragDisabled) {
        cursor: move;
    }

    .dragHandle {
        opacity: 0;
    }
}

.container.wide {
    position: relative;
    left: -$builderContainerLeftPadding - $fieldContainerLeftPadding - $handleWidth;
    width: calc(100% + #{2 * $fieldContainerLeftPadding} + #{2 * $handleWidth} + #{2 * $builderContainerLeftPadding});
}

.container:hover,
.container.editing {
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.2);
}

.container:hover .dragHandle,
.container:active .dragHandle,
.container.editing .dragHandle {
    opacity: 1;
}

.container.nonMovable {
    box-shadow: none;
}

.container.dragging {
    border: dashed 2px $border-color;
    box-shadow: none;

    .dragHandle,
    * {
        opacity: 0;
    }
}

.fieldContainer {
    position: relative;
    flex: 1;
    padding: 8px 24px 0 $fieldContainerLeftPadding;
}

.container:hover .fieldContainer,
.container:active .fieldContainer {
    background-color: white;
}

.fieldPreview {
    position: relative;

    &Cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
    }
}

.fieldTools {
    display: flex;
    position: absolute;
    z-index: 10;
    top: -8px;
    right: 24px;
    transition: all 200ms;
    opacity: 0;
    font-size: 18px;
}

.container:hover .fieldTools {
    opacity: 1;
}

.toolButton {
    width: 36px;
    height: 32px;
    padding: 0;
    margin-right: 8px;

    &:last-child {
        margin-right: 0;
    }
}