@import '~@emplo/react-inspinia/dist/inspinia/scss/_variables';
@import '../../../themes/ats/variables';

.container {
    display: grid;
    grid-template-areas:
        "circle stage"
        ". change";
    justify-items: start;
    align-items: center;
    grid-template-columns: auto 1fr;
}

.stageName {
    color: #2A3846;
    font-weight: 600;
    grid-area: stage;
}

.stageIndicator {
    grid-area: circle;
}

.btnChangeStage {
    color: $navy;
    font-size: 11px;
    line-height: 13px;
    padding: 4px 8px 4px 0;
    margin: 0;
    grid-area: change;
}

.candidateInfo {
    &Email {
        color: $color-text-default;
        font-size: 11px;
        font-weight: 600;
    }
}