.noData {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    color: rgba(50, 50, 50, 0.3);
}