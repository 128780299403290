@import '../../../../themes/ats/variables';

.wrapper {
    height: 40vh;
    overflow: auto;
    align-self: stretch;
}

.listContainer {
    display: table;
    width: 100%;
}

.item {
    display: table-row;

    &:first-child {
        .col {
            border-top-width: 1px;
        }
    }
}

.col {
    display: table-cell;
    border: solid 1px $border-color;
    border-width: 0 0 1px 0;
    padding: 24px 12px 24px 0;

    &:first-child {
        padding-left: 24px;
    }

    &:last-child {
        padding-right: 24px;
    }

    &Name {
        width: 100%;

        &UserName {
            font-size: 16px;
            font-weight: 600;
        }
    }

    &Photo {
        &Container {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }

        img {
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }

    &Score {
        &Wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &Date {
            font-size: 11px;
            font-weight: 600;
            margin-top: 2px;
        }
    }
}

.scoreStars {
    font-size: 16px;
}