// vendors npm
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~animate.css/animate.min.css';
@import '~toastr/build/toastr.min.css';

$fa-font-path: "~font-awesome/fonts";
@import '~font-awesome/scss/font-awesome.scss';

// inspinia
@import '~@emplo/react-inspinia/dist/inspinia/scss/inspinia.css';
@import '~@emplo/react-inspinia/dist/inspinia/scss/overrides.scss';

// ats theme
@import 'themes/ats/theme.scss';