/* sped-up version of animate.css animations */
.animated {
    &.a200 {
        animation-duration: 200ms !important;
    }

    &.a300 {
        animation-duration: 300ms !important;
    }

    &.a500 {
        animation-duration: 500ms !important;
    }
}

@keyframes backdropFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.5;
    }
}

.backdropFadeIn {
    animation-name: backdropFadeIn;
}