@import '~@emplo/react-inspinia/dist/inspinia/scss/_variables';

// colors
$color-primary: $navy;
$color-success: $blue;
$color-info: $lazur;
$color-warning: $yellow;
$color-danger: $red;
$color-glass-green: rgba(26, 179, 148, 0.1);

$color-text-default: #2E4050;
$color-light-gray: #969FA7;
$color-light-gray2: #AAAAAA;

// typography
$text-small-size: 10px;
$text-small-line-height: 16px;

// spacings
$spacing: 40px;
$padding-h: $spacing;
$padding-v: $spacing;
$padding: $padding-v $padding-h;

$padding-h-md: 24px;
$padding-v-md: 16px;

$border-radius: 4px;