@import '../../../themes/ats/variables';

.hiringManagerMark {
    background-color: $color-warning;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: solid 1px white;
    position: absolute;
    bottom: -4px;
    left: -4px;

    >i {
        color: white;
        font-size: 10px;
    }
}