.container {

    input[readonly]:not(:global(.disabled)),
    select[readonly]:not(:global(.disabled)),
    textarea[readonly]:not(:global(.disabled)) {
        background: initial;
        opacity: initial;
    }
}

.questionnaireName {
    font-size: 30px;
    line-height: 36px;
    font-weight: 600;
    margin: 0 0 24px;

    button {
        font-size: 18px;
    }
}

.formContainer {
    padding: 24px 16px 8px 8px;
}

.formHeaderFieldsContainer {
    margin: 0 23px 0 32px;
}

.leftPanel {
    position: relative;

    @media(max-width: 991px) {
        top: 0 !important;
    }

    &CandidateProfileTitle {
        margin-top: 32px !important;
    }
}