.container {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
}

.photo {
    margin-right: 0.5em;

    &Wrapper {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        position: relative;

        &Large {
            width: 64px;
            height: 64px;
        }

        &Tiny {
            width: 20px;
            height: 20px;
        }

        &Small {
            width: 36px;
            height: 36px;
        }

        img {
            width: 100%;
            opacity: 0;
        }
    }
}

.personal {
    flex: 1;
}

.name {

    &Small,
    &Tiny {
        font-size: 13px;
    }

    &Large {
        font-size: 16px;
        font-weight: 700;
    }
}