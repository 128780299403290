@import '../../../../themes/ats/variables';

.step {
  display: flex;
  align-items: center;
  height: 20px;
  color: $color-light-gray2;
}

.stepLabel {
  margin: 0 20px;
}

.stepLine {
  border: 1px solid $border-color;
  flex: 1;
}