.node {

    &Phone,
    &StageChanged {
        font-size: 20px;
    }
}

.scoreStars {
    font-size: 16px;
}

.meeting {
    &Name {
        font-size: 16px;
    }

    &InvitedPeople {
        font-size: 12px;
        margin-bottom: 8px;
    }

    &Date {
        font-style: italic;
        margin-bottom: 8px;
    }
}