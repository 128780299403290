@import '../../../themes/ats/variables';

:global(.project-list) .table {
  tr td {
    padding: 0;

    &.colActions {
      width: auto;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  a {
    display: block;
    padding: 24px 10px;

    color: $color-text-default;

    &:hover {
      color: $color-text-default;
    }
  }
}

.col {
  &Title {
    width: 40%;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;

    &Name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 15vw;
    }
  }

  &ApplicationDate,
  &Stage,
  &Score {
    width: 20%;
  }
}

.avatarContainer {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  img {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: solid 1px $border-color;
  margin-top: 10px;
  color: $color-text-default;
}

.score {
  font-size: 16px;
}

.btn {
  &Actions {
    width: 36px;
    height: 32px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}