#root .rbt-token,
#root .rbt-token-active {
    color: white;
    background-color: $color-primary;
}

#root .rbt-input-multi.focus {
    box-shadow: none;
    border-color: $color-primary;
}

/* main nav header - disable background */
.nav-header {
    background-image: none;
}

/* page-wrapper - display flex to allow filling the full viewport with components */
#page-wrapper {
    display: flex;
    flex-direction: column;
}

/* dont change z-index for the sidebar */
.navbar-static-side {
    z-index: initial;
}

/* dropdown item background color - primary */
.dropdown-menu .dropdown-item {
    &:hover {
        background-color: $color-glass-green;
    }
}

/* modal with ibox - round corners */
.ReactModal__Content {
    .ibox-title {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    .ibox-footer {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

/* react-bootstrap-typeahead - hide hint */
.rbt-input-hint {
    display: none !important;
}

/* datepicker z-index */
.react-datepicker-popper {
    z-index: 999 !important;
}

/* react tooltip - z-index has to be higher than fixed layout's top bar */
.__react_component_tooltip {
    z-index: 1035;
}