.cvModal,
.appFormModal,
.actionLogPhoneCallModal,
.giveScoreModal {
    min-width: 800px;
    max-width: 90vw;
    margin: auto;
}

:global(.ReactModal__Overlay).modalOverlay {
    padding-top: 0;
}

.modalContent {
    max-height: 70vh;
    overflow: auto;

    &AppForm {
        height: 600px;
        background: white !important;
        overflow: auto;
    }

    &Cv {
        background: white !important;
        padding-left: 0;
        padding-right: 0;
    }
}

.footer {
    margin: 6px 1px;
}