@import '../../../themes/ats/variables';

.container {
    width: 100%;
}

.listContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.item {
    display: flex;
    align-items: center;
    background: white;
    border: solid 1px $border-color;
    padding: 8px;

    &Name {
        flex: 1;
        margin-right: 8px;
    }
}

.btn {
    &Add {
        margin-top: 14px;
    }

    &Delete {
        color: $color-danger;
    }
}