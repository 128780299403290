.modalContent {
    padding: 0;
    height: 70vh;
    background: white !important;
}

.button {
    width: 90px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}