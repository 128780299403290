@import '../../../../themes/ats/variables';

$timelineBgColor: rgba($gray, .75);

.listContainer {
    max-width: 650px;
}

.item {
    display: flex;

    &:last-child .timelineContainer .timelinePath {
        display: none;
    }
}

.timeline {
    &Container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 1rem;
    }

    &Node {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: solid 3px $timelineBgColor;
        color: white;
        font-size: 16px;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        >* {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $lazur;
        }
    }

    &Path {
        flex: 1;
        width: 3px;
        background: $timelineBgColor;
    }
}

.card {
    $cardBubbleSize: 7px;

    min-height: 50px;
    background-color: $timelineBgColor;
    border-radius: 2px;
    margin-bottom: 1.5rem;
    padding: 16px;

    &Container {
        position: relative;
        flex: 1;
        margin-left: $cardBubbleSize;
    }

    &Bubble {
        position: absolute;
        top: 16px;
        right: 100%;
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-right: 7px solid $timelineBgColor;
    }

    &Title {
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 16px;
    }

    &Date {
        font-size: 10px;
    }
}