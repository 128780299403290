@import '../../themes/ats/variables';

$handleWidth: $padding-v-md; // width of the handle for drag&drop

.dragHandle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: $handleWidth;
    border-radius: $border-radius 0 0 $border-radius;
    background-color: $color-text-default;
    color: #ffffff;
    // cursor: grab;
    user-select: none;
    transition: all 200ms;

    // &:active {
    //     cursor: grabbing;
    // }

    >i {
        font-size: 16px;

        &:first-child {
            margin-right: 2px;
        }
    }
}