@import '../../../../themes/ats/variables';

.container {
    position: relative;
}

.select {
    overflow: hidden;

    >* {
        cursor: default;
    }

    &.active {
        border-color: $navy;
    }

    &Label {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.menu {
    background: white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.option {
    padding: 14px 20px;
    cursor: pointer;

    * {
        cursor: pointer;
    }

    &Label {
        width: 100%;
    }

    &All {
        border-bottom: solid 1px $border-color;
    }

    &:hover {
        background-color: darken(white, 2%);
    }
}