@import '../../../themes/ats/variables';

.title {
    font-size: 14px;
}

.candidatesIcon {
    color: $color-light-gray;
}

.mainTools {
    display: flex;

    :global(.fa, .fab) {
        font-size: 22px;
    }
}

.toolBtn {
    width: 40px;

    i {
        font-size: 20px;
    }
}

.candidatesCount {
    color: $color-text-default;
    font-size: 44px;
    line-height: 50px;
}

.candidatesBtn {
    margin-bottom: 5px;
    width: 180px;
}

.keywords {
    word-wrap: break-word;
}

.keyword {
    &:not(:last-child) {
        &::after {
            content: ', ';
        }
    }
}