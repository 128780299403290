@import '../../../themes/ats/variables';

.container {
    position: relative;
}

:global(.questionnaire-element-editor) {
    &:last-child {
        .button {
            opacity: 1;
            background-color: $color-glass-green;
        }
    }

    &.dragging {
        .button {
            opacity: 0 !important;
        }
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 0;
    margin: 0 24px 0 32px;
    transition: all 200ms;
    cursor: pointer;
    opacity: 0;
    border: solid 1px transparent;
    background-color: $color-glass-green;

    &__active,
    &:hover:not(&__dragging) {
        opacity: 1;
    }

    >i {
        color: $color-primary;
    }

    &__disabled {
        opacity: 0 !important;
        cursor: default;
    }
}

.listIcon {
    color: $navy;
    font-size: 1.3em;
}

.dropdown {
    min-width: 230px;
    z-index: 1040;
}