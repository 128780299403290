@import '../../../../themes/ats/variables';

.container {
    display: flex;
    justify-content: flex-end;
}

.itemsContainer {
    display: flex;
    flex-direction: row-reverse;
    justify-items: flex-start;
    align-items: center;
    cursor: pointer;
}

.item {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: solid 2px white;
    position: relative;

    &Member {
        background-position: center;
        background-size: cover;
    }
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    &Add {
        color: $color-primary;
        border: dashed 1px $color-primary;

    }
}